import { useContext, useState} from 'react';
import { StyleSheet, AppRegistry, TextInput, Button, Text, View, Image } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Login from './components/login'
import Orchestra from './components/orchestra';
import OrchestraTwo from './components/orchestratwo';
import OrchestraThree from './components/orchestrathree';
import Lessons from './components/lessons';
import Overview from './components/overview';
import LessonsDetail from './components/lessonsDetail';
import Profile from './components/profile';
import NewPassword from './components/newpassword';
import FormContextProvider from "./store/context/FormContextProvider"
import AuthContextProvider,{AuthContext} from "./store/context/AuthContext"
import { NavigationContainer } from '@react-navigation/native'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { RootSiblingParent } from 'react-native-root-siblings';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';


const Stack = createStackNavigator()
const Tab = createBottomTabNavigator();


const GradientHeader = props => (
  <View style={{ backgroundColor: '#eee' }}>
      <LinearGradient
        colors={['red', 'blue']}
        style={[StyleSheet.absoluteFill, { height: Header.HEIGHT }]}
      >
        <Header {...props} />
      </LinearGradient>
    </View>
  )


function LogoTitle() {
  return (
    <View style={{height:100}}>
    <View style={ styles.header}>
        <LinearGradient
        colors={['rgba(0,225,183,1)', '#EEEEEE']}
        style={styles.background}
      />
      <View>
    <Image
      style={styles.logo}
      source={require('./assets/karajan-akademie-logo.png')}
    />
    </View>
    </View>
    </View>
  );
}

function Auth() {
  return(
    <Stack.Navigator screenOptions={{
      headerShown: true,
      headerStyle: { backgroundColor: '#EEEEEE', height: 80 },
    }} >
    <Stack.Screen options={{ header: (props) => <LogoTitle {...props} /> }}  name="Login" component={Login} />
    <Stack.Screen options={{ header: (props) => <LogoTitle {...props} /> }}  name="NewPassword" component={NewPassword} />
    </Stack.Navigator>
  )
}

function Authenticated() {
  return(
  <Tab.Navigator screenOptions={{
    tabBarActiveTintColor: 'black',
  }}>
  <Tab.Screen name="OrchestraOverview" component={MainStackNavigator} options={{headerShown:false,
      tabBarLabel:"Orchestra",
      tabBarIcon: ({ focused, color, size }) => (
        <MaterialCommunityIcons name="violin" color={focused ? 'black' : 'grey'} size={size} />
      ),}}/>
  <Tab.Screen name="LessonsOverview" component={SecondStackNavigator} options={{ headerShown:false,
      tabBarLabel: 'Lessons',
      tabBarIcon: ({focused, color, size }) => (
        <MaterialCommunityIcons name="school" color={focused ? 'black' : 'grey'} size={size} />
      ),
    }} />
  <Tab.Screen name="ProfileOverview" component={ThirdStackNavigator} options={{
    unmountOnBlur: true,
    headerShown:false,
      headerTitle: (props) => <LogoTitle {...props} />,
      tabBarLabel: 'Profile',
      tabBarIcon: ({focused, color, size }) => (
        <MaterialCommunityIcons name="account" color={focused ? 'black' : 'grey'} size={size} />
      ),
    }} />
  {/* <Tab.Screen name="Login" component={Login} /> */}
</Tab.Navigator>)
}

function MainStackNavigator() {
  return(
    <Stack.Navigator  screenOptions={{
      headerShown: true,
      headerStyle: { backgroundColor: '#EEEEEE', height: 80 },
    }}>
    <Stack.Screen options={{ header: (props) => <LogoTitle {...props} /> }} name="Orchestra" component={Orchestra} />
    <Stack.Screen name="Select Pieces" component={OrchestraTwo} />
    <Stack.Screen name="Enter Shifts" component={OrchestraThree} />
    </Stack.Navigator>
  )
}

function SecondStackNavigator() {
  return(
    <Stack.Navigator screenOptions={{
      headerShown: true,
      headerStyle: { backgroundColor: '#EEEEEE', height: 80 },
    }}>
    <Stack.Screen options={{ header: (props) => <LogoTitle {...props} /> }} name="Lessons" component={Lessons} />
    <Stack.Screen name="Enter Details" component={LessonsDetail} />
    </Stack.Navigator>
  )
}

function ThirdStackNavigator() {
  return(
    <Stack.Navigator screenOptions={{
      headerShown: true,
      headerStyle: { backgroundColor: '#EEEEEE', height: 80 },
    }}>
    <Stack.Screen options={{ header: (props) => <LogoTitle {...props} /> }} name="Profile" component={Profile} />
    <Stack.Screen name="Overview" component={Overview} />
    </Stack.Navigator>
  )
}

function Navigation(){
  const authCtx = useContext(AuthContext);
  return (
  <NavigationContainer>
    {/* <Authenticated/>  */}
  {!authCtx.isAuthenticated && <Auth/> }
  {authCtx.isAuthenticated && <Authenticated/> }
  </NavigationContainer>
  );
}

export default function App({navigation}) {
  return (
    <RootSiblingParent> 
    <FormContextProvider>
    <AuthContextProvider>
    <Navigation/>
 
    </AuthContextProvider>
    </FormContextProvider>
    <StatusBar style="dark" />
    </RootSiblingParent>

  );
}

const styles = StyleSheet.create({
  header: {
    flex: 1,
    backgroundColor: "#eeeeee",
    height:120,
    alignItems: 'center',
  },
  background: {
    position:'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 120,
  },
  input: {
    width:200,
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  logo: {
    padding:10,
    marginTop:40,
    width: 220,
    height: 60,
    resizeMode: 'contain',
  },  
});
