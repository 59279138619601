
import React, {useState,useContext} from 'react';
import Title from './title';
import ButtonBlack from './buttonblack';
import DropDownPicker from 'react-native-dropdown-picker';
import { View, Text, StyleSheet, SafeAreaView, ScrollView} from 'react-native';
import {FormContext} from '../store/context/FormContextProvider';
import {postOrchestra} from '../util/http';
import Toast from "react-native-root-toast";
import {AuthContext} from '../store/context/AuthContext';

function OrchestraThree({navigation}){

const {userData, isChecked, setChecked, setUserData, dropDownValue, setDropDownValue} = useContext(FormContext);
const authCtx = useContext(AuthContext);

const [openOne, setOpenOne] = useState(false);
const [openTwo, setOpenTwo] = useState(false);
const [openThree, setOpenThree] = useState(false);

const [valueOne, setValueOne] = useState(null);
const [valueTwo, setValueTwo] = useState(null);
const [valueThree, setValueThree] = useState(null);

function postFormData() {
  postOrchestra(userData,authCtx.id);
  Toast.show('Your orchestra entry has successfully been sent', {
    duration: Toast.durations.LONG,position: Toast.positions.TOP,});
  setUserData({})    
  setDropDownValue(null);
  navigation.navigate("Orchestra");
}

const [academicShifts, setAcademicShifts] = useState([
  {label: '1', value: '1'},
  {label: '2', value: '2'},
  {label: '3', value: '3'},
  {label: '4', value: '4'},
  {label: '5', value: '5'},
  {label: '6', value: '6'},
  {label: '7', value: '7'},
  {label: '8', value: '8'},
  {label: '9', value: '9'},
  {label: '10', value: '10'}
]);  

const [substituteShifts, setSubstituteShifts] = useState([
  {label: '1', value: '1'},
  {label: '2', value: '2'},
  {label: '3', value: '3'},
  {label: '4', value: '4'},
  {label: '5', value: '5'},
  {label: '6', value: '6'},
  {label: '7', value: '7'},
  {label: '8', value: '8'},
  {label: '9', value: '9'},
  {label: '10', value: '10'}
]);  

const [tournee, setTournee] = useState([
  {label: '1', value: '1'},
  {label: '2', value: '2'},
  {label: '3', value: '3'},
  {label: '4', value: '4'},
  {label: '5', value: '5'},
  {label: '6', value: '6'},
  {label: '7', value: '7'},
  {label: '8', value: '8'},
  {label: '9', value: '9'},
  {label: '10', value: '10'},
]);  

function pressHandler(){
  navigation.navigate("Lessons");
}

 return(

  <SafeAreaView style={styles.container}>
  <View style={styles.view}>
  <Title title="3/3 Enter shifts" />
  <View>
  <Text style={styles.paragraph}>Academic Shifts</Text>
  </View>
  <View style={{zIndex:3}}>
  <DropDownPicker
   maxHeight={150}
  style={{border:"1px solid black",padding: 10, marginTop:8,marginBottom:24,flexDirection: 'row',}}
      open={openOne}
      value={valueOne}
      items={academicShifts}
      setOpen={setOpenOne}
      setValue={setValueOne}
      dropDownContainerStyle={{
        backgroundColor: "#dfdfdf",flexDirection: 'row',padding:5
      }}
      selectedItemContainerStyle={{
        backgroundColor: "white",flexDirection: 'row'
      }} 
      onChangeValue={()=>(setUserData({...userData,"academicShifts":valueOne}))}
    />
  </View>
  <View>
  <Text style={styles.paragraph}>Substitute Shifts</Text>
  </View>
  <View style={{zIndex:2}}>
  <DropDownPicker
   maxHeight={150}
  style={{border:"1px solid black",padding: 10,marginTop:8,marginBottom:24,flexDirection: 'row',}}
      open={openTwo}
      value={valueTwo}
      items={substituteShifts}
      dropDownContainerStyle={{
        backgroundColor: "#dfdfdf",flexDirection: 'row',padding:5
      }}
      setOpen={setOpenTwo}
      setValue={setValueTwo}
      selectedItemContainerStyle={{
        backgroundColor: "white",flexDirection: 'row'
      }}
      onChangeValue={()=>(setUserData({...userData,"substituteShifts":valueTwo}))}
    />
  </View>

  <View>
  <Text style={styles.paragraph}>Tournee</Text>
  </View>
  <View style={{zIndex:1}}>
  <DropDownPicker 
  maxHeight={150}
  style={{border:"1px solid black",padding: 10,marginTop:8,marginBottom:24,flexDirection: 'row',}}
      open={openThree}
      value={valueThree}
      items={tournee}
      setOpen={setOpenThree}
      dropDownContainerStyle={{
        backgroundColor: "#dfdfdf",flexDirection: 'row',padding:5
      }}
      setValue={setValueThree}
      onChangeValue={()=>(setUserData({...userData,"tournee":valueThree}))}
      selectedItemContainerStyle={{
        backgroundColor: "white",flexDirection: 'row'
      }}
    />
  </View>
  <ButtonBlack onPress={()=>{postFormData();}} text="Save the data"/>
  </View>
  </SafeAreaView>
 )
};

const styles = StyleSheet.create({
  view: {
    paddingHorizontal:32,
  },
  container: {
    paddingBottom:60,
    flex:1
   },
 button: {
  paddingVertical: 14,
  width:155,
  backgroundColor: 'white',
  borderStyle: 'solid',
  height:48,
  borderColor: "black",
  borderWidth: 1
  },
  input: {
    width:200,
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
buttonText: {
  color:"black",
  textAlign: "center",
  fontWeight:"800"
  }
});

export default OrchestraThree;