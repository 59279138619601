import React, { useContext,useEffect,useState,useReducer } from 'react';
import { View, Text, StyleSheet, SafeAreaView, ScrollView, Pressable } from 'react-native';
import Title from './title';
import {AuthContext} from '../store/context/AuthContext';
import axios from "axios"
import { useIsFocused } from "@react-navigation/native";
import LoadingOverlay from './loadingOverlay';
const BACKEND_URL= 'https://karajan.timeboxing.eu'
// const BACKEND_URL= 'http://localhost:3000'

function Lessons(navigation) {
const isFocused = useIsFocused();  
const authCtx = useContext(AuthContext);
const [isFetching, setIsFetching] = useState();
const [orchestra,setOrchestra] = useState([]);
const [lessons,setLessons] = useState([]);
const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);

useEffect(
  ()=>{
    // if(isFocused){ 
      const fetchData = async () => {
        setIsFetching(true);
        const id = authCtx.id;
        const response = await axios.get(BACKEND_URL + "/user/"+id);
        // console.log(response)
        setOrchestra(response.data.orchestra);
        setLessons(response.data.lessons);
        setIsFetching(false);

      }
      fetchData();
    // }
  }, [reducerValue]
); 

const deleteHandler = async (programId)=> {
  const id = authCtx.id;
  const response = await axios.post(BACKEND_URL + "/deleteprogram/",{userId: id, programId: programId});
  console.log("response" + response);
  forceUpdate();
}

const deleteLessonHandler = async (lessonId)=> {
  const id = authCtx.id;
  const response = await axios.post(BACKEND_URL + "/deletelesson/",{userId: id, lessonId: lessonId});
  console.log("response" + response);
  forceUpdate();
}

if(isFetching) {
  return <LoadingOverlay/>
}

return(

  <SafeAreaView style={styles.container}>
  <ScrollView style={styles.scrollview}>
  <Title title="Study Book Overview" />
  <Text style={{fontWeight:"bold",paddingBottom:10}}>Orchestra Entries</Text>


  <Text>{orchestra == ""? "There are no entries yet": ""}</Text>

  <View style={{marginBottom:20}}>
  {orchestra.map((e)=>{return(<View key={e._id}><Text  >Program: {e.program}{"\n"}
Works:{"\n"}{e.works.map((e,index)=>{return(<Text key={index} >{index+1}. {e.werk}{"\n"}</Text>)})}
Acdemic Shifts: {e.academicShifts}{"\n"}
Substitute Shifts: {e.substituteShifts} {"\n"}
Tournee: {e.tournee}</Text>
  <Pressable style={styles.button} onPress={()=>{deleteHandler(e._id)}}>
  <Text style={styles.text}> Delete Entry</Text>
  </Pressable></View>)})}
  </View>
  <Text style={{fontWeight:"bold",marginTop:20,paddingBottom:10}}>Lesson Entries</Text>
  <Text>{lessons == ""? "There are no entries yet": ""}</Text>
  {lessons.map((e)=>{return(<View key={e._id}><Text >Day: {e.day}{"\n"}Hours: {e.hours}{"\n"}Teacher: {e.teacher}{"\n"}Pianist Name: {e.pianistName}</Text>
  <Pressable style={styles.button} onPress={()=>{deleteLessonHandler(e._id)}}><Text style={styles.text}>Delete Entry</Text>
  </Pressable></View>  )})}
  </ScrollView>
  </SafeAreaView>
)
};
const styles = StyleSheet.create({
  scrollview:{
    paddingHorizontal:32
  },
  container: {
    paddingBottom:32,
    flex: 1,
   },
   button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 2,
    paddingHorizontal: 4,
    borderRadius: 4,
    borderWidth:1,
    borderColor:'black',
    elevation: 3,
    backgroundColor: 'white',
    marginBottom:16,
    marginTop:10,
    height:30,
    width:100
  },
  text: {
    fontSize: 11,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    color: 'black',
  },
});

export default Lessons;