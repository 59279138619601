import React from "react";
import { useContext, useState, useEffect } from "react";
import Title from "./title";
import ButtonBlack from "./buttonblack";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { FormContext } from "../store/context/FormContextProvider";
import Checkbox from "expo-checkbox";

function OrchestraTwo({ navigation }) {
  //  Navigation Handler
  function pressHandler() {
    if (userData.works == "" || userData.works == undefined) {
      setValidation("Please select pieces, min. 1");
    } else if (userData.works.every((obj) => obj.isChecked === false)) {
      setValidation("Please select pieces, min. 1");
    } else {
      setValidation("");
      navigation.navigate("Enter Shifts");
    }
  }

  const {
    setFetchedPrograms,
    fetchedPrograms,
    userData,
    isChecked,
    setChecked,
    setUserData,
  } = useContext(FormContext);
  const [validation, setValidation] = useState();

  const filteredProgram = fetchedPrograms.filter((obj) => {
    return obj.value === userData.program;
  });

  const data = filteredProgram[0].werke.map((werk, index) => ({
    werk,
    isChecked: false,
    id: index + 1,
  }));

  const [works, setWorks] = useState(data);

  const handleChange = (id) => {
    let temp = works.map((work) => {
      if (id === work.id) {
        return { ...work, isChecked: !work.isChecked };
      }
      return work;
    });
    setWorks(temp);
    setUserData({ ...userData, works: temp });
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scrollview}>
        <Title title="2/3 Select Pieces" />

        {works.map((werk) => {
          return (
            <View key={werk.id} style={styles.section}>
              <Checkbox
                style={styles.checkbox}
                value={werk.isChecked}
                onValueChange={() => handleChange(werk.id)}
                color={werk.isChecked ? "black" : undefined}
              />
              <Text style={styles.paragraph}>{werk.werk}</Text>
            </View>
          );
        })}
        <Text style={{ color: "red" }}>{validation}</Text>
        <ButtonBlack onPress={pressHandler} text="Next: Enter Shifts" />
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  scrollview:{
    paddingHorizontal:32
  },
  container: {
    paddingBottom:16,
    flex: 1,
  },
  button: {
    paddingVertical: 14,
    width: 155,
    backgroundColor: "white",
    borderStyle: "solid",
    height: 48,
    borderColor: "black",
    borderWidth: 1,
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 16,
  },
  input: {
    width: 200,
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  checkbox: {
    margin: 8,
  },
  buttonText: {
    color: "black",
    textAlign: "center",
    fontWeight: "800",
  },
});

export default OrchestraTwo;
