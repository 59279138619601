import React, { useContext,useEffect,useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Button } from 'react-native';
import Title from './title';
import ButtonBlack from './buttonblack';
import ButtonWhite from './buttonwhite';
import {AuthContext} from '../store/context/AuthContext';
import axios from "axios"
import { Link } from '@react-navigation/native';
import Anchor from './anchor';
import LoadingOverlay from './loadingOverlay';
const BACKEND_URL= 'https://karajan.timeboxing.eu'
// const BACKEND_URL= 'http://localhost:3000'

function Lessons({navigation}) {
const authCtx = useContext(AuthContext);
const [name,setName] = useState();
const [email,setEmail] = useState();
const [orchestra,setOrchestra] = useState([]);
const [isFetching, setIsFetching] = useState();

function pressHandler(){
  // navigation.navigate("Overview");
  navigation.navigate("Overview");
}

useEffect(
  ()=>{
    const fetchData = async () => {
      setIsFetching(true);
      const id = authCtx.id;
      const response = await axios.get(BACKEND_URL + "/user/"+id);
      // console.log(response);
      setName(response.data.name);
      setEmail(response.data.email);
      setOrchestra(response.data.orchestra)
      setIsFetching(false);
    }
    fetchData();
  }, []
); 

if (isFetching) {
  return <LoadingOverlay/>
}

return(
  <TouchableOpacity >
  <View  style={styles.container}>
  <Title title="Profile" />
  <Text>{name}</Text>
  <Text>{email}</Text>
  <ButtonWhite style={{marginTop:10}} onPress={pressHandler} text="Show my entries"/>
  {/* <Link style={{paddingTop:20,paddingBottom:20,fontWeight: "bold"}} to={{ screen: 'Overview', params: { id: 'jane' } }}>
      Show my Entries
    </Link> */}

    <View style={styles.link}> 
    <Anchor href="https://davidroettger.github.io/karajan-akademie-app/privacypolicy"> 
      Privacy Policy 
    </Anchor> 
  </View> 



  <ButtonBlack onPress={authCtx.logout} text="Logout"/>
  </View>
  </TouchableOpacity>
)
};
const styles = StyleSheet.create({
  container: {
    paddingHorizontal:32
   }
});

export default Lessons;