
import React, {useState,useContext} from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Button, SafeAreaView, ScrollView,  } from 'react-native';
import Title from './title';
import ButtonBlack from './buttonblack';
import DatePicker from 'react-native-modern-datepicker';
import {FormContext} from '../store/context/FormContextProvider';
// import DateTimePicker from '@react-native-community/datetimepicker';

function Lessons({navigation}){

  const {selectedDate,setSelectedDate} = useContext(FormContext);
  function pressHandler(){
      navigation.navigate("Enter Details");
  }

  const saveDate = (date) =>{
    setSelectedDate(date)
    // const setit = ()=>{setUserData({...userData,"lessonDate":selectedDate})}
    // setit();
  }

  return(
  <SafeAreaView style={styles.container}>
  <ScrollView style={styles.scrollview}>
  <View style={styles.view}>
  <Title title="1/2 Select Day" />
  <DatePicker options={{
    backgroundColor: '#eee',
    textHeaderColor: '#000000',
    textDefaultColor: '#000000',
    selectedTextColor: '#ffffff',
    mainColor: '#000000',
    textSecondaryColor: '#000000',
    borderColor: 'rgba(122, 146, 165, 0.1)',}
  }
    onSelectedChange={(date) => (saveDate(date)) } mode="calendar"
    style={{ maxWidth: 500 }}
    />
  <ButtonBlack onPress={pressHandler} text="Next: Enter Details"/>
  </View>
  </ScrollView>
  </SafeAreaView>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom:20
   },
   view: {
    paddingHorizontal:32
   },
 button: {
  paddingVertical: 14,
  width:155,
  backgroundColor: 'white',
  borderStyle: 'solid',
  height:48,
  borderColor: "black",
  borderWidth: 1
  },
buttonText: {
  color:"black",
  textAlign: "center",
  fontWeight:"800"
  }
});

export default Lessons;