import React, {useEffect} from 'react';
import Title from './title';
import ButtonBlack from './buttonblack';
import DropDownPicker from 'react-native-dropdown-picker';
import { View, Text, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import {FormContext} from '../store/context/FormContextProvider';
import {useContext, useState} from 'react';

function Orchestra({navigation}){

// Get states
const {setFetchedPrograms,fetchedPrograms, userData, setUserData,setDropDownValue, dropDownValue} = useContext(FormContext);

// Dropdown Validation
const [validation, setValidation] = useState();
const [open, setOpen] = useState(false);
const [value, setValue] = useState(null);

//  Navigation Handler
function pressHandler(){
  if (userData.program == "" || userData.program == undefined) {
    setValidation("Please select a program");
  } else {
    setValidation("");
    navigation.navigate("Select Pieces");
  }
}

 return(
  <TouchableOpacity>
  <View style={styles.container}>
  <Title style={{marginTop:80}} title="1/3 Select Program" />

  <DropDownPicker
      open={open}
      value={dropDownValue}
      items={fetchedPrograms}
      placeholder="Select an item"
      style={{border:"1px solid black",paddingVertical: 10,flexDirection: 'row',padding:10}}
      dropDownStyle={{marginTop: 2}}
      textStyle={{
        fontSize: 14
      }}
      labelStyle={{
        justifyContent: 'flex-end',flexDirection: 'row'
      }}
      dropDownContainerStyle={{
        backgroundColor: "#dfdfdf",flexDirection: 'row',padding:5
      }}
      // listItemLabelStyle={{
      //   color: "black"
      // }}
      selectedItemContainerStyle={{
        backgroundColor: "white",flexDirection: 'row'
      }}
      setOpen={setOpen}
      setValue={setDropDownValue}
      setItems={setFetchedPrograms}
      onChangeValue= {()=>(setUserData({...userData,"program":dropDownValue}))
      }
  />
  {/* <TextInput value={userData['name']} onChange={(e)=>setUserData({...userData,"name":e.target.value})} style={styles.input} ></TextInput>
  */}
  {/* <Text style={styles.paragraph}>{dropDownValue}</Text> */}
  {/* <Text>Orchestra</Text> */}
  {/* <Text>
  {fetchedPrograms.map((post) =>(<div>
                <span>{post.VA_Projekt_Name} </span>
                </div>))}
    </Text>  */}
  <Text style={{color:"red",marginTop:10}} >{validation}</Text>
  <ButtonBlack onPress={pressHandler} text="Next: Select Pieces"/>
  </View>
  </TouchableOpacity>
 )
};

const styles = StyleSheet.create({

 container: {
  paddingHorizontal:32
 },
 button: {
  paddingVertical: 14,
  width:155,
  backgroundColor: 'white',
  borderStyle: 'solid',
  height:48,
  borderColor: "black",
  borderWidth: 1
  },
  input: {
    width:200,
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
buttonText: {
  color:"black",
  textAlign: "center",
  fontWeight:"800"
  }

});

export default Orchestra;