import axios from "axios"

const BACKEND_URL= 'https://karajan.timeboxing.eu'
// const BACKEND_URL= 'http://localhost:3000'

export async function createUser(email,password) {
   const response = axios.post(BACKEND_URL+'/auth/signup', {email:email, password:password});
}

export async function authenticate(email, password) {
   const response = await axios.post(BACKEND_URL+'/auth/login', {email:email, password:password});
   const token = response.data.token;
   // console.log("UserID:"+ response.data.userId)
   return response.data;
}

export async function login(email,password){
   return authenticate(email,password);
}