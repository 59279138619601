import axios from "axios"

const BACKEND_URL= 'https://karajan.timeboxing.eu'
// const BACKEND_URL= 'http://localhost:3000'
// Fetch Programs Function


export async function deleteProgram(){
    const fetchData = async () => {
    const response = await axios.get(BACKEND_URL + "/deleteProgram");
    return response.data;
    }
}

export async function fetchUser(){
    const fetchData = async () => {
    const response = await axios.get(BACKEND_URL + "/auth/user");}
}

export async function fetchPrograms(){
 const response = await axios.get(BACKEND_URL + "/projects");
//  console.log(response.data)

 const programs = [];

 for (let key in response.data) {
    const programObject = {
        label: response.data[key].VA_Projekt_Name + " (" + response.data[key].Buchungen + "–" + response.data[key].Letzte_Buchung+")" ,
        value: response.data[key].VA_Projekt_Name,
        werke: response.data[key].Werke,
        id: response.data[key]._id
    };
    programs.push(programObject);
 }
//  console.log(programs);
 
//  return response.data;
return programs;
}

export async function postOrchestra(data,id) {
    console.log(data)
    const response = await axios.post(BACKEND_URL + "/user/postorchestra",{data,id:id});
    // console.log(response.data)
    return response.data;
}

export async function postLesson(selectedDate,hours,teacher,pianistName,id) {
    console.log(hours);
    const response = await axios.post(BACKEND_URL + "/user/postlesson",{selectedDate:selectedDate,hours:hours,teacher:teacher,pianistName:pianistName,id:id});
    return response.data;
}



