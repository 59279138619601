import { createContext, useState, useEffect } from "react";

export const FormContext = createContext({});
import {fetchPrograms} from '../../util/http';

function FormContextProvider({children}){
    const [isChecked, setChecked] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [userData, setUserData] = useState([]);
    const [dropDownValue, setDropDownValue] = useState();
    const [fetchedPrograms, setFetchedPrograms] = useState([]);
    const [hours, setHours] = useState('');
    const [teacher, setTeacher] = useState('');
    const [pianistName, setPianistName] = useState('');
    useEffect(()=> {
      async function getPrograms(){
        const programs = await fetchPrograms()
        setFetchedPrograms(programs);
      }
      getPrograms();
    }, []);
    return <FormContext.Provider value={{hours, setHours,teacher, setTeacher,pianistName, setPianistName,selectedDate,setSelectedDate,userData, setUserData, isChecked, setChecked,dropDownValue,setDropDownValue,fetchedPrograms, setFetchedPrograms}}>
        {children}
    </FormContext.Provider>
}

export default FormContextProvider;
