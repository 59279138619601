
import React, {useState,useContext} from 'react';
import { View, Text, StyleSheet, SafeAreaView, ScrollView,TextInput } from 'react-native';
import Title from './title';
import ButtonBlack from './buttonblack';
import {FormContext} from '../store/context/FormContextProvider';
import {AuthContext} from '../store/context/AuthContext';
import Toast from "react-native-root-toast";
import {postLesson} from '../util/http';
// import DateTimePicker from '@react-native-community/datetimepicker';

function Lessons({navigation}){

  const authCtx = useContext(AuthContext);
  const [teacherValidation, setTeacherValidation] = useState();
  const [hoursValidation, setHoursValidation] = useState();

  const {selectedDate,hours, setHours, teacher, setTeacher, pianistName, setPianistName} = useContext(FormContext);

  // function postFormData() {
  //   postLesson(selectedDate,hours,teacher,pianistName,authCtx.id);
  //   Toast.show('Your lesson entry has successfully been sent', {
  //   duration: Toast.durations.LONG,position: Toast.positions.TOP,});   
  //   navigation.navigate("Lessons");
  // }

  function postFormData() {
  if (hours == "" || hours == undefined) {
    setHoursValidation("Please enter hours");
    }
  if (teacher == "" || teacher == undefined) {
    setTeacherValidation("Please enter a teacher");
  } else {
    setTeacherValidation("");
    postLesson(selectedDate,hours,teacher,pianistName,authCtx.id);
    Toast.show('Your lesson entry has successfully been sent', {
    duration: Toast.durations.LONG,position: Toast.positions.TOP,});   
    navigation.navigate("Lessons");
  }
}

  return(
    <SafeAreaView style={styles.container}>
    <ScrollView style={styles.scrollview}>
  <View style={styles.view} >
  <Title title="2/2 Enter Details" />
  <Text>Hours in total  <Text style={{color:"red"}}>{hoursValidation}</Text></Text>

  <TextInput
        style={styles.input}
        onChangeText={setHours}
        value={hours}
        keyboardType="numeric"
      />
  <Text>Teacher <Text style={{color:"red"}}>{teacherValidation}</Text></Text>

  <TextInput
        style={styles.input}
        onChangeText={setTeacher}
        value={teacher}
      />
  <Text>Pianist Name (optional)</Text>
  <TextInput
        style={styles.input}
        onChangeText={setPianistName}
        value={pianistName}
      />
  <ButtonBlack onPress={()=>{postFormData();}} text="Send lesson"/>
  </View>
    </ScrollView>
    </SafeAreaView>
)
};

const styles = StyleSheet.create({
  view: {
    paddingHorizontal:32
   },
 input:{
  height: 40,
  borderWidth: 1,
  padding: 10,
  marginTop:8,
  marginBottom:24,
 },
 container:{
  flex:1,
  paddingBottom:20
 },
 button: {
  paddingVertical: 14,
  width:155,
  backgroundColor: 'white',
  borderStyle: 'solid',
  height:48,
  borderColor: "black",
  borderWidth: 1
  },
buttonText: {
  color:"black",
  textAlign: "center",
  fontWeight:"800"
  }
});

export default Lessons;