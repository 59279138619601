import React,{useContext, useState} from 'react';
import { View, Text, StyleSheet,TextInput, Alert } from 'react-native';
import Title from './title';
import ButtonBlack from './buttonblack';
import {login} from '../util/auth';
import {AuthContext} from '../store/context/AuthContext';
import LoadingOverview from './loadingOverlay';
import Anchor from './anchor';

function Login({navigation}){

const [isLoggingIn, setIsLoggingIn] = useState(false);
const [email, setEmail] = useState();
const [password, setPassword] = useState();
const authCtx = useContext(AuthContext);

async function loginHandler(email, password){
  setIsLoggingIn(true);
  try {
    // Alert.alert('Authentication failed!','Could not login ')
    // const token = await login("david.roettger@gmx.net","karajan");

    // Login is a function in /util/auth.js
    const responseData = await login(email,password);
    authCtx.authenticate(responseData.token);
    authCtx.saveId(responseData.userId);
    // console.log(authCtx.isAuthenticated);
  }
  catch {
    Alert.alert('Authentication failed!','Could not login ')
    setIsLoggingIn(false);
  }
};

if (isLoggingIn){
  return <LoadingOverview/>
}

return (
    <View style={styles.container}>
    <Title style={{marginTop:30}} title="Login" />
    <Text>E-Mail address</Text>
    <TextInput autoCapitalize='none' onChangeText={(text) => setEmail(text)} style={styles.input} ></TextInput>
    <Text>Password</Text>
    <TextInput secureTextEntry={true} autoCapitalize='none' onChangeText={(text) => setPassword(text)} style={styles.input} ></TextInput>
    <ButtonBlack onPress={()=>loginHandler(email,password)} text="Login"/>
    <Anchor href="https://davidroettger.github.io/karajan-akademie-app/privacypolicy"> 
      Privacy Policy 
    </Anchor> 
    <Text
        style={{marginTop:32,marginLeft:0}}
        text="Reset Password"
        onPress={() => navigation.navigate('NewPassword')}
      > Reset Password 
    </Text>
    </View>
)
}

const styles = StyleSheet.create({
    container: {
      paddingHorizontal:32
    },
    button: {
     paddingVertical: 14,
     width:155,
     backgroundColor: 'white',
     borderStyle: 'solid',
     height:48,
     borderColor: "black",
     borderWidth: 1
     },
     input: {
       width:300,
       height: 50,
       fontSize:16,
       margin: 0,
       borderWidth: 1,
       padding: 12,
       marginTop:8,
       marginBottom:18,
     },
   buttonText: {
     color:"black",
     textAlign: "center",
     fontWeight:"800"
     }
   });


export default Login;