import React from "react";
import { Text, View, StyleSheet, TextInput } from "react-native";
import Title from "./title";
import ButtonBlack from "./buttonblack";
import axios from "axios";

const NewPassword = ({navigation}) => {
  const [email, onChangeEmail] = React.useState("");
  const [message, changeMessage] = React.useState(null);

  const resetPassword = () => {
    // Send a POST request
    axios({
      method: "post",
      url: "https://karajan.timeboxing.eu/auth/resetpassword",
      data: {
        email: email,
      },
    })
      .then(function (response) {
        onChangeEmail("");
        changeMessage(response.data.message);
      })
      .catch((err) => {
        changeMessage(err.response.data.message)
      });
  };

  return (
    <View style={styles.container}>
      <Title style={{ marginTop: 30 }} title="Reset Password" />
      <Text>E-Mail address</Text>
      <TextInput
        style={styles.input}
        onChangeText={onChangeEmail}
        value={email}
      />
      <Text>{message}</Text>
      <ButtonBlack onPress={() => resetPassword()} text="Reset Password " />
      <Text
        style={{ marginTop: 32 }}
        text="Reset Password"
        onPress={() => navigation.navigate("Login")}
      >
        To Login Screen
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 32,
  },
  button: {
    paddingVertical: 14,
    width: 155,
    backgroundColor: "white",
    borderStyle: "solid",
    height: 48,
    borderColor: "black",
    borderWidth: 1,
  },
  input: {
    width: 300,
    height: 50,
    fontSize: 16,
    margin: 0,
    borderWidth: 1,
    padding: 12,
    marginTop: 24,
    marginBottom: 18,
  },
  buttonText: {
    color: "black",
    textAlign: "center",
    fontWeight: "800",
  },
});

export default NewPassword;
