
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

const ButtonWhite = props => (
  <TouchableOpacity onPress={props.onPress}>
  <View style={styles.button}>
  <Text style={styles.buttonText}>{props.text}</Text>
  </View>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
 button: {
  paddingVertical: 14,
  width:155,
  marginTop:10,
  marginBottom:30,
  backgroundColor: 'white',
  borderStyle: 'solid',
  height:48,
  borderColor: "black",
  borderWidth: 1
  },
buttonText: {
  color:"black",
  textAlign: "center",
  fontWeight:"800"
  }
});

export default ButtonWhite;