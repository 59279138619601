import { createContext, useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const AuthContext = createContext({
  email:"",
  token: "",
  isAuthenticated: true,
  authenticate: (token) => {},
  saveEmail: (email) => {},
  logout: () => {},
});

function AuthContextProvider({ children }) {
  const[authToken, setAuthToken] = useState();
  const[email, setEmail] = useState();
  const[id, setId] = useState();

  useEffect(()=>{
    async function fetchToken(){
    const storedToken = await AsyncStorage.getItem('token');
    if (storedToken){
      setAuthToken(storedToken);
    }
    const storedId= await AsyncStorage.getItem('id');
    if (storedId){
      setId(storedId);
    }
  } 
    fetchToken();
  },[])

  function authenticate(token) {
    setAuthToken(token);
    AsyncStorage.setItem('token', token);
  }

  function saveEmail(email) {
    setEmail(email);
  }

  function saveId(id) {
    setId(id);
    console.log("save")
    AsyncStorage.setItem('id', id);
  }

  function logout() {
    setAuthToken(null);
    AsyncStorage.removeItem('id');
    AsyncStorage.removeItem('token');

  }

  const value = {
    email:email,
    token: authToken,
    id: id,
    isAuthenticated: !!authToken,
    authenticate: authenticate,
    logout: logout,
    saveEmail: saveEmail,
    saveId: saveId,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;

}
export default AuthContextProvider;
